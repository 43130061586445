/*jshint esversion: 6 */

import '../../js/general.js';

import { createApp } from 'vue';
import App from './App.vue';

import VueSmoothScroll from 'vue3-smooth-scroll';


import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon, FontAwesomeLayers,  FontAwesomeLayersText} from "@fortawesome/vue-fontawesome";

import { faCookie , faAngleUp, faBars, faXmark, faCircleXmark, faCircle} from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
library.add(faFacebook, faInstagram, faCookie, faAngleUp, faBars, faXmark, faCircleXmark, faCircle);

createApp(App)
.component("font-awesome-icon", FontAwesomeIcon)
.component('font-awesome-layers', FontAwesomeLayers)
.component('font-awesome-layer-text', FontAwesomeLayersText)
.use(VueSmoothScroll)
.mount('#app');